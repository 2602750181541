@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xlDesktop {
  @media (min-width: 1280px) {
    @content;
  }
}
